const Instagram = ({ size }) => <>
    <svg version="1.1" id="Warstwa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 200 200"
        space="preserve"
        style={{ width: size, height: size }}
    >
        <g id="Warstwa_2_00000130609419068668354390000016436937326705606837_" style={{ fill: '#000' }}>
            <rect y="0" width="200" height="200" />
        </g>
        <g id="Warstwa_1_00000001656422855469750250000010153413657811872444_" style={{ fill: '#fff' }}>
            <g>
                <path class="st0" d="M157.5,74.9c-0.1-0.8-0.3-1.6-0.4-2.4c-0.3-1.8-0.6-3.7-1.1-5.5c-3.5-13.4-14-22.4-27.3-23.5
			c-10.2-0.8-20.6-0.9-30.7-0.9H97c-8.1,0-16.7,0-25.1,0.9C55.9,45,44.5,56.8,43.4,72.8c-1.3,18.1-1.3,36.4,0,54.5
			c1.1,15.9,12.3,27.4,28.5,29.5c1.8,0.2,3.7,0.4,5.5,0.6c0.8,0.1,1.7,0.2,2.5,0.2h40.7h0.1c1-0.1,2.1-0.2,3.1-0.3
			c2.3-0.2,4.7-0.5,7.1-0.9c15.2-3,25-14,26.3-29.5c0-0.5,0.1-1.1,0.2-1.7c0.1-0.3,0.1-0.6,0.2-0.9l0-0.2V75.2L157.5,74.9
			L157.5,74.9z M100.2,143.1h-2v0c-1.7,0-3.3,0-5,0c-5.5,0.1-11.2,0.1-16.8-0.2c-3.4-0.2-7.1-1.1-10.2-2.6
			c-5.5-2.7-8.6-7.8-8.8-14.4c-0.3-14.2-0.5-32.8,0-51.7c0.3-10.5,7.5-17,19.1-17.2c6.7-0.2,13.5-0.2,20.2-0.2
			c8.9,0,17.8,0.1,26.5,0.2h0.7c2.6,0,5.5,0.7,8.6,1.8c6.5,2.5,10.1,7.7,10.6,15.4c0.6,8.3,0.6,16.8,0.6,25.4c0,9.1,0,17.2-0.6,25.4
			c-0.7,11-7.6,17.6-18.8,18c-5.5,0.2-11.2,0.1-16.7,0.1C105.2,143.1,102.7,143.1,100.2,143.1L100.2,143.1z"/>
                <path class="st0" d="M130.7,100.1c0.1-16.8-13.6-30.6-30.4-30.7c-0.1,0-0.1,0-0.2,0c-8.1,0-15.7,3.1-21.4,8.8
			c-5.8,5.8-9,13.4-9.1,21.6c-0.1,16.9,13.6,30.6,30.4,30.7c0.1,0,0.1,0,0.2,0c8.1,0,15.7-3.1,21.5-8.8
			C127.5,115.9,130.7,108.3,130.7,100.1z M116.7,100.1c0,4.4-1.8,8.5-5,11.6c-3.1,3.1-7.2,4.7-11.4,4.7c-0.1,0-0.1,0-0.2,0
			c-9.1-0.1-16.4-7.5-16.4-16.6c0-9.1,7.4-16.5,16.5-16.5c4.4,0,8.6,1.7,11.7,4.8C115,91.4,116.7,95.6,116.7,100.1z"/>
                <path class="st0" d="M129.9,79L129.9,79c4.7,0,8.6-3.9,8.6-8.6c0-4.7-3.8-8.6-8.5-8.7c-0.1,0-0.1,0-0.1,0c-4.6,0-8.5,3.9-8.6,8.5
			c-0.1,2.3,0.8,4.6,2.5,6.2S127.6,78.9,129.9,79L129.9,79z"/>
            </g>
        </g>
    </svg>
</>

export default Instagram