const Behance = ({ size }) => <>
    <svg version="1.1" id="Warstwa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 200 200" 
        style={{ width: size, height: size }}
        >

        <g id="Warstwa_2_00000013188307585615866610000004131443598117798272_" style={{ fill: '#000' }}> 
            <rect y="0" width="200" height="200" />
        </g>
        <g id="Warstwa_1_00000007407930361200125780000005411142605904333700_" style={{ fill: '#fff' }}> 
            <g>
                <path class="st0" d="M30,56.3c13.7,0,27.3,0,41,0c5.6,0,11.1,0.7,16.2,3.3c12.3,6.2,15,24.6,4.9,33.3c-1.2,1.1-2.6,1.9-4,2.8
			c2.1,1.3,4.2,2.4,6,3.8c6.4,5.3,8.8,15.4,5.6,24.3c-3.3,9.3-9.7,15.2-19.7,16.7c-3.7,0.6-7.4,0.9-11.1,1c-12.9,0.1-25.9,0-38.8,0
			L30,56.3L30,56.3z M47.4,126.4c8.7,0,17.1,0.4,25.5-0.1c6.6-0.4,9.9-5.1,9.7-12.1c-0.2-5.9-3.9-9.5-10.6-9.7
			c-7.6-0.3-15.3-0.3-22.9-0.4c-0.5,0-1,0.2-1.7,0.2L47.4,126.4L47.4,126.4z M47.4,89.6c8.5,0,16.7,0.1,24.9-0.1
			c1.6,0,3.2-0.8,4.6-1.5c3.2-1.6,4.5-4.9,3.9-9.4c-0.4-3.3-2.4-6.1-5.5-6.3c-9.2-0.8-18.5-1.2-27.9-1.7V89.6z"/>
                <path class="st0" d="M169,123.1c-0.7,5-3.3,8.5-6.3,11.7c-12.5,13-38.8,11.6-50-2.5c-3.6-4.6-5.1-10-5.8-15.7
			c-0.8-7.1-0.4-14,2-20.7c4.2-11.7,14.6-18.5,28.3-18.9c14.6-0.4,25.3,5.9,30.2,18c2.5,6.2,2.6,12.8,2.5,19.7h-46.3
			c-0.1,7.2,3.5,12.8,9.5,14.8c7.2,2.4,13.9,0.6,18.1-5c0.4-0.6,1.2-1,1.9-1.3c0.4-0.2,0.9,0,1.4,0
			C159.2,123.1,163.9,123.1,169,123.1L169,123.1z M124.1,103.7h28.3c0-7.7-5.5-13-13.8-13.2C130.4,90.2,124.5,95.5,124.1,103.7z"/>
                <path class="st0" d="M119.7,70.6V60.4h36.7v10.2H119.7z" />
            </g>
        </g>
    </svg>
</>

export default Behance