
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { Link } from "react-router-dom"
import SaloniLogo from './SaloniLogo'
import Instagram from '../icons/Instagram'
import Behance from '../icons/Behance'
import Web from '../icons/Web'

const Wrapper = styled.div`
    overflow: auto;
    height: 100%;
`

const Inner = styled.div`
    max-width: 648px;
    margin: -40px auto 60px;
    padding: 0 24px;
    text-align: justify;
    line-height: 1.3;

    img {
        width: 100%;
    }
`

const SocialLink = styled.div`
    > a {
        display: flex;
        align-items: center;
        color: inherit; 
        text-decoration: inherit;

        > span {
            display: ${p => p.isMobile? 'none' :'block'};
            margin-left: 12px;
            margin-top: 0;
            margin-bottom:  0;
        }
    }
`

const SocialLinksWrapper = styled.div`
    display: flex;
    margin: auto;
    margin-top: 36px;
    justify-content: space-between;
    align-items: center;
`


const About = ({ about, isMobile }) => {

    return <Wrapper>
        <Link to='/'> <SaloniLogo color='black' /> </Link>
        <Inner>
            <ReactMarkdown children={about} />

            <SocialLinksWrapper isMobile={isMobile}>
                <SocialLink isMobile={isMobile}>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/saloni.jpg">
                        <Instagram size={34} />
                        <span>saloni.jpg</span>
                    </a>
                </SocialLink>
                <SocialLink isMobile={isMobile}>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.behance.net/josaloni">
                        <Behance size={34} />
                        <span>josaloni</span>
                    </a>
                </SocialLink>
                <SocialLink isMobile={isMobile}>
                    <a href="mailto:joanna.saloni@gmail.com">
                        <Web size={34} />
                        <span> joanna.saloni@gmail.com </span>
                    </a>
                </SocialLink>
            </SocialLinksWrapper>

        </Inner>
    </Wrapper>

}


export default About