const Arrow = ({ right }) => <>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
        style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'square',
            strokeMiterlimit: 10,
            strokeWidth: 48,
            width: '100%',
            transform: right ? 'rotate(-90deg)' : 'rotate(90deg)',
        }}
    >
        <polyline points="112 184 256 328 400 184" />
    </svg>
</>

export default Arrow