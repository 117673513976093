import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Letter from './Letter'

const StyledWord = styled.div`
    cursor: pointer;
    overflow: hidden;
    font-size: ${p => p.isMobile ? '60px' : '72px'};
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;

`

const Word = ({ word, top, clientHeight, clientWidth, ordered, orderedRowTop, isMobile, onClick, temp }) => {
    const [lettersWidths, setLettersWidths] = useState(null)
    const itemsRef = useRef([]);

    useEffect(() => {
        const letters = itemsRef.current.filter((el) => { return el != null })
        setLettersWidths(letters.map(letter => letter.offsetWidth))
    }, [isMobile]);

    const totalWidth = lettersWidths?.reduce((a, b) => a + b, 0)
    let totalMargin = 0

    return (
        <StyledWord isMobile={isMobile} onClick={onClick}>

            {[...word].map((letter, index) => {
                totalMargin += lettersWidths?.[index - 1] || 0

                return <Letter
                    key={index}
                    ordered={ordered}
                    index={index}
                    marginLeft={totalMargin}
                    orderedRowTop={orderedRowTop}
                    top={top}
                    totalWidth={totalWidth}
                    clientWidth={clientWidth}
                    clientHeight={clientHeight}
                    isMobile={isMobile}
                    temp={temp}
                >
                    <span ref={el => itemsRef.current[index] = el}>
                        {letter === ' ' ? <span>&nbsp;</span> : letter}
                    </span>
                </Letter>
            })}

        </StyledWord>
    );
}

export default Word
