const Web = ({ size }) => <>
    <svg version="1.1" id="Warstwa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 200 200" style={{ width: size, height: size }}  >

        <g id="Warstwa_2_00000089548637028166412810000016384072521869042833_" style={{ fill: '#000' }}>
            <rect y="0" width="200" height="200" />
        </g>
        <g id="Warstwa_1_00000057110018569052483700000012751328707164418992_" style={{ fill: '#fff' }}>
            <path class="st0" d="M74.9,157.4c-9.6-4.4-18-11.4-24.1-20.1c-7.2-10.1-11-22.1-11-34.6c0-8,1.6-15.9,4.7-23.3
		c3.1-7.2,7.4-13.6,13-19.1c5.6-5.6,12-9.9,19.1-12.8c7.4-3.2,15.2-4.8,23.3-4.8s16.1,1.7,23.6,5.2c7,3.2,13.5,7.7,19,13.5
		c11.1,11.5,17.5,26.5,17.5,41.5c0,5.8-1.5,15.1-5.6,22.1c-3.1,5.4-7.4,9.5-12.6,11.6c-4.6,2.1-9.8,2.5-14.7,1.1
		c-4.1-1.1-7.7-3.2-10.4-6.3c-1.8-2.1-3.1-4.6-3.8-7c-4.9,4.2-11.4,6.7-18.3,6.7c-15.7,0-28.3-12.6-28.3-28.2s12.6-28.2,28.3-28.2
		c6.9,0,13.3,2.5,18.3,6.7l1.2-5.1h11.9v43.2c0,2.6,2.2,4.4,4.7,5.1c0.5,0.1,1.2,0.2,2.2,0.2c2.6,0,6.6-1.1,9.8-6.7
		c2.6-4.4,3.7-11,3.7-15.3c0-24.3-22.2-46.6-46.6-46.6c-25.7,0-46.6,20.9-46.6,46.6c0,9.6,3,18.9,8.5,26.8
		c4.7,6.8,11.1,12.1,18.7,15.6L74.9,157.4L74.9,157.4z M110.6,102.7c0-3.8-1.4-7.5-4.1-10.2c-2.6-2.7-6.1-4.2-9.8-4.2
		s-7.2,1.5-9.9,4.2c-2.6,2.7-4.1,6.4-4.1,10.2s1.5,7.4,4.1,10.2c2.7,2.7,6.2,4.2,9.9,4.2s7.2-1.5,9.8-4.2
		C109.3,110.1,110.6,106.5,110.6,102.7z"/>
        </g>
    </svg>
</>

export default Web