import { useState, useEffect } from 'react'
import { Routes, Route, useLocation, Link } from "react-router-dom"
import styled from 'styled-components'
import Section from './components/Section'
import SectionMobile from './components/SectionMobile'
import About from './components/About'
import MenuButton from './components/MenuButton'
import SaloniLogo from './components/SaloniLogo'
import Menu from './components/Menu'
import MainTitle from './components/MainTitle'


import useWindowDimensions from './useWindowDimensions'
import { useMediaQuery } from 'react-responsive'
import './App.css'

const query = `{
  mainTitleCollection {
    items {
      mainTitle 
    }
  }
  aboutCollection {
    items {
      about
    }
  }
  projectCollection {
    items {
      title
      subtitle
      imagesCollection {
        items {
          url
        }
      }
      contentfulMetadata {
        tags {
          id
          name
        }
      }
    }
  }
}`


const StyledApp = styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: ${p => p.isMobile ? 'auto' : 'hidden'};
    overflow: hidden;
`

const App = () => {
  let location = useLocation()
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' })
  const [data, setData] = useState(null)
  const [about, setAbout] = useState(null)
  const [mainTitle, setMainTitle] = useState(null)
  const [filteredData, setFilteredData] = useState(null)
  const [sections, setSections] = useState(null)
  const [menuOpen, setMenuOpen] = useState(true)
  const { height, width } = useWindowDimensions()
  const [selectedSection, setSelectedSection] = useState(null)
  const sectionHeight = (isMobile ? height * 0.6 : height) / sections?.length

  useEffect(() => {
    const loca = location?.pathname
    loca === '/' ? setMenuOpen(true) : setMenuOpen(false)
    setSelectedSection(loca?.replaceAll("/", ""))
  }, [location])

  useEffect(() => {
    const filtered = selectedSection === 'all'
      // 'all' is every without logos
      ? data?.filter(project => project?.contentfulMetadata?.tags?.some(tag => tag.id !== 'logos'))
      : data?.filter(project => project?.contentfulMetadata?.tags?.some(tag => tag.id === selectedSection))
    filtered?.length && setFilteredData(filtered)
  }, [data, selectedSection])


  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_SPACE_ID}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors)
        }
        setData(data.projectCollection.items)
        setMainTitle(data.mainTitleCollection.items[0].mainTitle)
        setAbout(data.aboutCollection.items[0].about)
        setSections([...new Set(data.projectCollection.items?.map(pro => {
          return pro.contentfulMetadata.tags
        }).flat().map(tag => tag.name)), 'All', 'About'])
      })
  }, [])

  return (
    <StyledApp isMobile={isMobile} >

      {menuOpen && <MainTitle
        text={mainTitle}
        isMobile={isMobile}
      />}

      {menuOpen && <Link style={{ position: 'absolute', zIndex: 99999999 }} to='/'>
        <SaloniLogo color={menuOpen ? 'white' : 'black'} isMobile={isMobile} />
      </Link>}

      {!menuOpen && <MenuButton onClick={() => { setMenuOpen(!menuOpen) }} />}

      <Menu
        sections={sections}
        sectionHeight={sectionHeight}
        height={height}
        width={width}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />

      <Routes>
        {data ?
          <>
            <Route path="/about" element={<About about={about} isMobile={isMobile} />} />
            <Route path={selectedSection} element={
              isMobile ?
                <SectionMobile
                  projects={filteredData}
                  section={selectedSection}
                  windowHeight={height}
                  windowWidth={width}
                  open={!menuOpen}
                /> :
                <Section
                  projects={filteredData}
                  section={selectedSection}
                  windowHeight={height}
                  windowWidth={width}
                  open={!menuOpen}
                  isMobile={isMobile}
                />
            }
            />
          </>
          : 'loading'}
      </Routes>


    </StyledApp>
  )
}

export default App
