

import 'swiper/swiper-bundle.css';
import {
    useSpring,
    config,
    animated,
} from '@react-spring/web'



const Description = ({ top, open, zIndex, from, title, subtitle, windowWidth, windowHeight, active, isMobile }) => {
    const left = isMobile ? 15 : -25

    const { height, width, ...rest } = useSpring({
        config: config.default,
        from: {
            pointerEvents: 'none',
            width: 440,
            height: 'auto',
            position: 'absolute',
            top: top || 0,
            left: left,
            color: 'black',
            opacity: 1,
        },
        to: {
            cursor: 'pointer',
            top: open ? windowHeight - 70 : top || 0,
            left: open ? (- from?.from.left + 20 || 0) : left,
            width: open ? windowWidth : 440,
            height: 'auto',
            opacity: active ? 1 : 0,
        },
    })

    return (

        <animated.div
            data-swiper-parallax={open ? 0 : "-100"}
            style={{ ...rest, width, height, zIndex }}
        >
            {title && <h2 className='description' style={{ fontSize: 24, margin: 0 }}> {title} </h2>}
            {subtitle && <h3 className='description' style={{ fontSize: 18, margin: 0 }}> {subtitle} </h3>}
        </animated.div>


    )
}

export default Description
