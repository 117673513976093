import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { Pagination, Navigation, Mousewheel } from "swiper";
import { Link } from "react-router-dom"
import SaloniLogo from './SaloniLogo'

const StyledSection = styled.div`
    width: 100%;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all 0.3s ease-in-out;

`

const AllProjects = styled.div`
    width: 100%;
    overflow: auto; 
    
`


const SingleProject = styled.div`
    margin: 32px 32px 60px;
    .swiper-pagination-bullet {
        background-color: black;
        height: 7px;
        width: 7px;
    }
    .swiper-pagination-bullet-active {
        background-color: black;
    }
    
    .swiper-wrapper {
        align-items: center; 
    }
   .swiper-button-prev {
        &:after {
            content: "➔";
            color: black;
            transform: rotate(180deg);
        }
    };
    .swiper-button-next {
        &:after {
            content: "➔";
            color: black;
        }
    }
   
`

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    display: 'flex';
    align-items: center;
    justify-content: center;
`



const Description = styled.div`
    margin-top: 12px;
    h2 {
        font-size: 20px;
        margin: 0;
    }
    h3 {
        font-size: 15px;
        margin: 0;
    }
`


const SectionMobile = ({ projects }) => {




    return (
        <StyledSection>
            <AllProjects>
                <Link to='/'> <SaloniLogo color='black' /> </Link>
                {projects?.map((project, i) => <SingleProject key={i} >

                    <Swiper
                        pagination={{ clickable: true }}
                        navigation={false}
                        centeredSlides={true}
                        loop={true}
                        speed={600}
                        modules={[Pagination, Navigation, Mousewheel]}
                    >
                        {project.imagesCollection.items.map((item, i) => <SwiperSlide key={i}>
                            <StyledImage alt={item.title} src={item.url} />
                        </SwiperSlide>
                        )}
                    </Swiper>

                    <Description>
                        <h2> {project.title} </h2>
                        <h3> {project.subtitle} </h3>
                    </Description>

                </SingleProject>)}

            </AllProjects>



        </StyledSection>

    )
}

export default SectionMobile