
import styled from 'styled-components'

const StyledMainTitle = styled.div`
    position: fixed;
	z-index: 99999999;
	font-size: ${p => p.isMobile ? 11 : 13}px;
	right: ${p => p.isMobile ? 0 : 16}px;
	bottom: 16px;
	text-transform: uppercase;
	letter-spacing: 1.5px;
    color: white;
	width: ${p => p.isMobile ? '100%' : 'initial'};
	text-align: ${p => p.isMobile ? 'center' : 'initial'};
`


const MainTitle = ({ text, isMobile }) => {

	return <StyledMainTitle isMobile={isMobile}>
		<strong>
			{text}
		</strong>
	</StyledMainTitle>
}


export default MainTitle