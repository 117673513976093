import { useState, useEffect } from 'react'
import { useNavigate, Link } from "react-router-dom"
import styled from 'styled-components'
import Word from './Word'
import { useMediaQuery } from 'react-responsive'

const Wrapper = styled.div`
    position: absolute;
    background-color: black;
    transition: all 0.3s ease-in-out;
    transform: ${p => p.open ? 'translateY(-0vh)' : ' translateY(-100vh)'};
    z-index: ${p => p.open ? 9999999 : -999};
    width: 100%;
    height: 100%;
`

const Menu = ({ sections, sectionHeight, height, width, menuOpen, setMenuOpen }) => {
    // let navigate = useNavigate()
    const isMobile = useMediaQuery({ query: '(max-width: 1080px)' })
    const [ordered, setOrdered] = useState(sections?.map(() => false))
    const [temp, setTemp] = useState(false)

    useEffect(() => {
        const handleOrdering = (e) => {
            const sectionIndex = Math.floor(e.clientY / height * sections?.length)
            setOrdered(sections?.map((_, i) => (i === sectionIndex) ? true : false))
        }
        window.addEventListener('resize', handleOrdering)
        window.addEventListener('mousemove', handleOrdering)
        return () => {
            window.removeEventListener('resize', handleOrdering)
            window.removeEventListener('mousemove', handleOrdering)
        }
    }, [sections])

    // const handleSectionSelection = (section) => {
    //     if (section) {
    //         setMenuOpen(false)
    //         navigate(section.toLowerCase(), { replace: true })
    //     }
    // }

    useEffect(() => {
        const timeoutID = window.setTimeout(() => {
            setTemp(false)
        }, 2000)
        return () => window.clearTimeout(timeoutID)
    }, [temp])


    return (
        <>
            {sections && <Wrapper open={menuOpen} onClick={() => isMobile && setTemp(true)} >
                {sections.map((section, index) => {
                    const orderedRowInd = ordered?.findIndex(row => row === true)
                    return <Link  key={section + index} to={section.toLowerCase()}>
                        <Word
                            word={section}
                            top={(sectionHeight * index + sectionHeight / 2) + (isMobile ? height * 0.2 : 0)}
                            orderedRowTop={(sectionHeight * orderedRowInd + sectionHeight / 2) + (isMobile ? 90 : 0)}
                            clientWidth={width}
                            clientHeight={height}
                            ordered={ordered?.[index]}
                            isMobile={isMobile}
                            // onClick={() => handleSectionSelection(section)}
                            temp={temp}
                        />
                    </Link>
                })}
            </Wrapper>}
        </>
    )
}

export default Menu
