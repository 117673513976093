
import React, { useState, useRef } from 'react'
import 'swiper/swiper-bundle.css';
import {
    useSpring,
    config,
    animated,
} from '@react-spring/web'
import Arrow from './Arrow'

const RegularImage = ({ title, tileSize, onClick, top, open, from, zIndex, windowWidth, windowHeight, active, images, reff }) => {
    const itemsRef = useRef([])
    const [inner, setInner] = useState(0)
    const [loaded, setLoaded] = useState(images.map(img => false))

    const handleChangePrev = () => {
        (inner === 0) ? setInner(images.length - 1) : setInner(inner - 1)
    }
    const handleChangeNext = () => {
        (inner === images.length - 1) ? setInner(0) : setInner(inner + 1)
    }

    const { height, width, ...rest } = useSpring({
        config: config.stiff,
        from: {
            position: 'absolute',
            cursor: 'pointer',
            top: top || 0,
            left: 0,
            transition: 'opacity 0.3s',
            width: tileSize,
            height: tileSize,
            opacity: 1,
        },
        to: {
            opacity: active ? 1 : 0,
            width: open ? windowHeight : tileSize,
            height: open ? windowHeight : tileSize,
            top: open ? 0 : top || 0,
            left: open ? ((windowWidth - windowHeight) / 2) - (from?.from?.left || 0) : 0,

        },
        onStart: () => setInner(0),
    })


    const handleLoaded = (index) => {
        setLoaded(images.map((_, i) => (i === index) ? true : loaded[i]))
    }

    return (
        <div ref={reff}  >
            {open && <div style={{
                position: 'absolute',
                zIndex: 9999999,
            }}>
                <div
                    style={{
                        position: 'absolute',
                        top: windowHeight / 2 - 30,
                        left: (-  from?.from.left || 0),
                    }}>
                    {images.length > 1 && <button
                        onClick={handleChangePrev}
                        style={{
                            border: 'none',
                            zIndex: 99999999,
                            backgroundColor: 'transparent',
                            padding: 0,
                            cursor: 'pointer',
                            width: 45,
                        }}
                    >
                        <Arrow />
                    </button>}
                </div>
                <div style={{
                    position: 'absolute',
                    top: windowHeight / 2 - 30,
                    left: (windowWidth - from?.from.left - 45 || 0),
                }}>
                    {images.length > 1 && <button
                        onClick={handleChangeNext}
                        style={{
                            border: 'none',
                            zIndex: 99999999,
                            backgroundColor: 'transparent',
                            padding: 0,
                            cursor: 'pointer',
                            width: 45,
                        }}
                    >
                        <Arrow right />
                    </button>}
                </div>
            </div>}


            <animated.div style={{
                display: 'flex',
                ...rest, width, height,
                zIndex: zIndex,
            }}>


                {images.map((img, i) => {

                    return <img
                        onLoad={() => handleLoaded(i)}
                        key={i}
                        ref={el => itemsRef.current[i] = el}
                        alt={title}
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            zIndex: zIndex - i,
                            objectFit: 'contain',
                            flex: '0 0 auto',
                            transition: 'opacity 0.3s',
                            opacity: inner === i && loaded[i] ? 1 : 0,
                        }}
                        src={img.url}
                        onClick={active ? onClick : null}
                    />
                })}
            </animated.div>

        </div>
    )
}

export default RegularImage
