

const SaloniLogo = ({ onClick, color, isMobile }) => {

	return <>
		<svg
			onClick={onClick}
			xmlns="http://www.w3.org/2000/svg"
			x="0px" y="0px"
			viewBox="-151.9 364.1 282.4 121.9"
			style={{
				margin: 24,
				width: isMobile ? 140 : 170,
				cursor: 'pointer',
			}}
		>
			<path fill={color} d="M115.6,394.2c1.3,0.8,4.5-2.3,7.2-6.9c2.6-4.6,3.7-8.9,2.4-9.7c-1.3-0.8-4.5,2.3-7.2,6.9
	C115.3,389.1,114.3,393.5,115.6,394.2z"/>
			<path fill={color} d="M-71.7,425.4c-1.8-8-7.2-12.2-14.4-9c-2,0.9-4,2.3-5.9,2.5s-3.5-1.2-3-3.9c0.3-1.7,2.7-3.7,3.2-4.6c0.3-0.5,1.5-2.1,2-4.1
	c1.5-5.4,0.5-9-1.4-10.2c-2.3-1.5-5.6-0.7-8.7,0.1c-1.9,0.4-3.8,0.9-5.3,0.4c-1.5-0.5-2.7-2.2-2.4-4.8c0.4-3.4,2.6-7.7,4.9-10.4
	c2-2.4,3.9-4.3,5.7-5.8c2.9-2.3,5.9-4.2,8.9-5.7c1.3-0.6,2.6-1.2,4-1.9c9.5-4,18.6-4,25.5,0.2c3.3,2.1,4.4,7,4.8,8.9
	c1.1,5.2-0.3,9.7-0.1,14.9c0.2,4.3,0.9,8.7,3.4,10.4c2.5,1.7,7.4-0.8,9.3-5.7c1.4-3.5,0.7-6.6-0.1-9.2c-4.3-13.2-12.8-19.6-21-22
	c-11.9-4.2-28.2,1.6-42.4,15.8c-3.2,3.2-7.6,8.1-10,14.6c-1.1,3-1.5,5.8-1.1,8c0.3,2,1.2,3.3,2.5,4.1c2.4,1.3,5.8,0.3,8.3-0.8
	c0.9-0.4,1.8-0.8,2.7-1.3c3.5-1.7,7.2-3.5,10-2.3c1,0.5,1.4,2,1,3.9c-0.7,2.7-2.5,4.2-4.4,5.9c-2.2,1.8-4.4,3.7-5.3,7.3
	c-0.8,3.2,0,5.9,1.9,7c2.8,1.5,6.8-0.7,9.5-2.5c0.7-0.4,1.3-0.9,2-1.4c2.4-1.6,4.8-3.3,7.1-4c2.2-0.6,4.1-0.2,5.7,1.3
	c3.2,3.1,4,10.4,2.1,17.6c-2.6,9.6-9.2,20-18.1,28.7c-11.1,10.8-22.8,16.6-34.1,16.9c-0.3,0-0.5,0-0.7,0c0.1,0,0.2,0,0.2,0
	c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c0.1,0,0.1,0,0.1,0c-1.5,0-4.2-0.2-7.2-1c-3.1-0.9-5.9-2.5-8.2-4.6c-2.5-2.5-3.9-5.3-4.4-7.5
	c-0.7-3.3,0.6-7,2.3-10.2c1.4-2.5,3.2-4.8,4.9-7.1c2.1-2.9,4.3-5.9,5.7-9.3c0.8-2,1.2-4.1,0.7-5.5c-0.6-1.5-2.1-1.6-3.6-1.4
	c-2.7,0.4-5.6,1.6-8.4,4c-2.8,2.4-5.5,5.9-6.9,9.9c-0.6,1.8-0.9,3.7-0.9,5.4c0,0,0,1.1,0.1,1.6c2.3,16.7,12,26.6,25.9,26.6
	c0.2,0,0.4,0,0.6,0c11.5-0.2,23.6-6.2,35-17.2c9-8.8,15.7-19.4,18.3-29.1C-70.7,434-70.6,429.2-71.7,425.4L-71.7,425.4z"/>
			<path fill={color} d="M126.7,396.4l-0.8,0.4c5.1,12,5.8,35.4-2.9,55.4c-6.6,15.1-17.7,25.8-32.1,30.9c-5.7,2-9.6,2-11.4-0.2
	c-6.4-7.7,11.7-39.8,22.6-59c7.2-12.7,8.9-16,8.1-16.9c-0.1-0.1-0.4-0.3-0.9-0.2c-0.3,0.1-0.7,0.4-1.2,0.8
	c-2.4,1.6-6.1,6.5-9.5,11.4c-5.1,6.7-11.9,15.8-19.9,26.7c-6.3,8.6-13.4,18.3-19.2,25.8c-0.1,0.1-5.6,6.9-7.3,8.6
	c-2.4,2.5,3.8-11.1,5.4-14.9c0.5-0.9,4.3-9.5,8.6-18.2c4.1-8.2,8.8-16.3,10.6-21.2c2.4-6.2,5.9-16.9,2.5-18.9c-2-1.2-5.7,1.2-11,7.3
	c-7.2,8.4-18.9,27.5-29.2,44.4c-2.6,4.3-5.3,8.7-7.7,12.6c-0.1,0.2-4.5,7-6.1,8.7c-2.7,2.9,5-17.1,5.1-17.3c0.7-1.6,1.4-3.3,2.2-5.1
	c21.1-49,18.7-50.3,17.7-50.8c-1.6-0.9-5.1,3.7-11.5,13l-0.4,0.6c-2.5,3.7-5.3,8.1-12.1,18.6c-2.1,3.3-4.6,7.1-7.6,11.8
	c8-17.6,14.4-38,12.1-42.8c0,0-0.3-0.7-0.9-1.1c-1.6-1-5.4-1.6-13.2,4.1c-8.4,6-15.9,19.1-17.5,22c-4.3,7-8.8,13.8-13.5,20.5
	c-4.7,6.8-9.5,13.5-14.5,20c-1.1,1.3-4.7,5.5-7.6,7c-2.8,1.4,1.2-11.1,3.1-16.4c0.5-1.3,0.9-2.6,1.4-3.9c0.6-0.7,1.3-1.4,1.9-2.1
	c1.5-1.8,3-3.5,4.4-5.3l0,0.1c0.2-0.3,0.4-0.5,0.6-0.8c2-2.5,3.9-5,5.8-7.6c3.6-5,7.1-10.1,10.2-15.5c3.1-5.3,5.8-10.7,8.3-16.3
	c3.6-8.1,6.8-16.6,8.3-25.4c0.5-2.8,0.9-5.6,0.9-8.5c0-1.1,0-2.1-0.1-3.2c-0.1-0.8,0-1.8-0.4-2.6c-1.6-3.4-7,5.6-7.6,6.5
	c-2.6,4.1-4.9,8.4-7.1,12.7c-2.2,4.2-4,8.6-5.8,13c-2.8,6.9-5.5,13.9-8.1,20.9c-2.8,7.5-5.5,15-8.2,22.5c-1.3,3.7-2.6,7.4-3.9,11.1
	c-0.3,0.3-0.6,0.6-0.9,1c-3.6,3.8-7.7,7.1-11.6,10.6c-3.3,3-6.7,5.8-10.4,8.3c0,0-1.2,0.8-4.2,2.5c-3.2,1.6-6.8,2.9-10.4,2.4
	c-0.4-0.1-0.8-0.2-1.2-0.3c-4.9-1.8-6.9-9.1,1.6-20.5c6.7-9,20.3-19.7,25.4-23.6c1.2-1,2.2-1.7,2.6-2c-0.8,0.7,3.6-2.1,7.5-6.9
	c3.1-3.9,5.6-8.6,6.6-13.5c0.4-1.9,0.6-4,0.2-6c-0.1-0.7-1.3-3.6-3.8-4.6c-3.8-1.4-7.8-0.3-8.8-0.1c-2.3,0.5-4.6,1.3-6.7,2.4
	c0,0-0.1,0-0.1,0s-0.4,0.2-1,0.6c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.3,0.2-0.8,0.5-1.3,0.8c-0.1,0.1-0.2,0.1-0.3,0.2
	c-1.1,0.8-2.4,1.8-3.7,3.1c-3.1,3.1-6.8,6.9-8,11.9c-2.5,9.9,8,7,10.1,2.1c1.5-3.5-2-6.6-0.4-12.6c0.4-1.6,1.6-2.9,2.8-3.9
	c0.9-0.6,1.9-1.2,2.9-1.7c3.7-1.9,7.7-2.7,10.8-2.6c2,0,3.5,0.5,4.5,1.2c1.2,0.9,2,2.1,2.3,3.5c0.3,1.5,0.1,3.2,0.1,3.7
	c-0.3,2.4-1,4.7-1.9,6.9c-2.1,5-5.4,9.3-9.6,12.8c-3.2,2.7-6.8,4.9-10.5,6.7c-4,2-8.2,3.6-12.1,5.7c-4.8,2.5-9.2,5.9-12.7,10.1
	c-3.2,3.8-5.7,8.5-6,13.5c-0.2,4.2,1.3,8.3,4.6,11c2.6,2.1,5.8,3.3,9.2,3c4.8-0.5,9.2-3,13.1-5.7c4.4-3,8.2-6.6,12.2-10
	c2-1.7,4.1-3.5,6-5.3c0.9-0.8,1.7-1.7,2.5-2.5c-0.7,2-1.4,4-2.1,5.9c-1.3,3.6-2.5,7.2-3.8,10.7c-0.6,1.6-1.1,3.3-1.3,5
	c-0.1,0.8,0,1.9,0.9,2.1c1.3,0.3,2.7-1.2,3.5-2c2-1.9,3.8-4.1,5.5-6.3l0,0v0c0.5-0.6,0.9-1.2,1.4-1.7c2.6-3.3,5.1-6.7,7.6-10.1
	c5.5-7.6,10.9-15.4,16.1-23.3c0.2-0.4,0.4-0.7,0.7-1.1c-0.2,0.5-0.4,0.9-0.6,1.4c-0.5,1.3-1.1,2.5-1.6,3.6l0,0v0
	c-9.8,23.1-11.3,35.5-4.9,38.7c1,0.5,2.1,0.7,3.1,0.7c0.9,0,1.8-0.2,2.8-0.5h0l0,0c2.3-0.8,4.7-2.6,7.3-5.4
	c4.5-4.8,9.4-12.5,14.6-22.9c0,0,0-0.1,0.1-0.1l0,0c4.6-7.2,8.2-12.8,11.1-17.3c2.4-3.8,4.4-6.8,5.9-9.3c-0.3,0.5-0.5,0.9-0.5,0.9
	s0.3-0.4,0.6-1.1c1.2-1.9,2.2-3.4,3.1-4.8c1.5-2.1,3.2-4.1,4.3-4.3c3.9-1,0.2,10.5-4.5,23.6c-2.7,7.4-5.2,14.1-6.1,16.7
	c-0.4,1-0.8,2.2-1.1,2.9c-8.8,20.9-7.9,21.4-7.3,21.7c0.1,0,0.1,0.1,0.2,0.1c1.5,0,5.4-6,18-26.7c6-9.8,12.8-21,18.9-30.2
	c-0.1,0.1,6.7-10.1,11.5-8.3c4.6,1.7,0.5,11.7-2.3,19.5c-2.8,7.8-11.3,25-11.3,25s0.4-0.8,1.1-2.1c-1.9,3.7-4,7.6-6,11.6
	c-5.1,9.9-4.4,10.4-3.9,10.7c1.6,1,8.9-8.4,31.4-39c3.9-5.3,8-10.8,11.8-16c-0.3,0.4-0.4,0.7-0.4,0.7s3.1-4,5.4-5.5
	c2.2-1.6-0.3,3.8-6.7,17.6c-4.3,9.3-8.1,19.1-9,21.5l0,0l0,0c-0.1,0.4-0.2,0.6-0.2,0.6s0,0,0.1-0.1c-0.6,1.8-2.9,8.3-3.5,10
	c-0.7,2.1-3.6,11.1,3.2,11.1c4.8,0,8.6-1.4,8.6-1.4s0,0-0.1,0c0.8-0.2,1.7-0.5,2.6-0.8c14.6-5.2,25.9-16,32.6-31.4
	C132.5,432.2,131.8,408.5,126.7,396.4L126.7,396.4z M-30.8,457.3c2.5-7.1,5.1-14.3,7.7-21.4c2.7-7.3,5.4-14.6,8.3-21.9
	c2.3-5.9,4.6-11.8,7.3-17.6c1.2-2.6,2.5-5.1,3.8-7.6c2.3-3.1,5.2-5.5,3.9,4.3c-0.5,3.8-3.6,17.3-8.8,29.7
	c-5.9,14-12.4,22.8-13.9,24.8c-2.7,3.5-5.4,7-8.4,10.3C-31,457.6-30.9,457.4-30.8,457.3L-30.8,457.3z M15.2,456.3
	c-3.7,7.4-7.2,13.5-10.6,18c-0.6,0.7-1.7,2-3.6,3.6c-2.1,1.8-6,3.2-8.3,2.5c-4.8-1.4-6-5.9-4.7-16.2c1-8,5-15.9,6.3-19.3
	c0.3-0.8,0.6-1.5,1-2.3c1.8-4,3.7-7.7,5.7-11c2.6-3.8,10-14.1,15.7-15.9c7.1-2.2,8.1,2,6,16.3c-0.4,2.5-1.1,9.7-5.9,20.7
	C16.3,454,15.7,455.1,15.2,456.3L15.2,456.3z M80.3,464.8C80.3,464.8,80.3,464.7,80.3,464.8c0,0-0.1,0.2-0.1,0.3
	C80.2,465,80.3,464.9,80.3,464.8z"/>
		</svg>
	</>
}


export default SaloniLogo