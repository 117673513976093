import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Parallax, Pagination, Navigation, Mousewheel, FreeMode } from "swiper";
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import RegularImage from './RegularImage'
import Description from './Description'
import { useNavigate, useLocation } from "react-router-dom"
import slugify from 'slugify'
import { Link } from "react-router-dom"
import SaloniLogo from './SaloniLogo'

const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
}

const calculatePosition = (element) => {
    const root = document.documentElement
    const body = document.body
    const rect = element.getBoundingClientRect()
    const scrollTop = window.pageYOffset || root.scrollTop || body.scrollTop || 0
    const scrollLeft = window.pageXOffset || root.scrollLeft || body.scrollLeft || 0
    const clientTop = root.clientTop || body.clientTop || 0
    const clientLeft = root.clientLeft || body.clientLeft || 0

    return {
        top: Math.round(rect.top + scrollTop - clientTop),
        left: Math.round(rect.left + scrollLeft - clientLeft),
        height: rect.height,
        width: rect.width,
    }
}

const StyledSection = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${p => p.backgroundColor};
    position: absolute;
    transition: all 0.3s ease-in-out;
    transform: ${p => p.open ? 'translateY(0vh)' : ' translateY(99.9999vh)'};
    `

const DescriptionWrapper = styled.div`
    .description {
       opacity: ${p => p.open ? 1 : 0};
       transition: all 0.3s ease-in-out;
    }
    :hover {
        .description {
            opacity: 1;
        }
    }
`

const Mask = styled.div`
    position: absolute;
    height: 100%;
    left: 45px;
    right: 45px;
    width: calc(100% - 90px);
    z-index: 9999990;
    opacity: 0.0;
    pointer-events:  ${p => p.active ? 'initial' : 'none'};

`


const Section = ({ projects, open, section, windowHeight, windowWidth }) => {
    let navigate = useNavigate()
    let location = useLocation()

    const tileSize = 440
    const [topMargin, setTopMargin] = useState(projects?.map(() => 0))
    const [projectOpen, setProjectOpen] = useState(projects?.map(() => false))
    const [from, setFrom] = useState(null)
    const [lastTouched, setLastTouched] = useState(null)
    const [refs, setRefs] = useState(null)

    const handleClick = (e, i) => {
        navigate(`#${slugify(projects[i].title, { lower: true })}`, { replace: true })
        setLastTouched(i)
        const from = calculatePosition(e.target)
        projectOpen[i] === true ? setFrom(null) : setFrom({ from, i })
    }

    useEffect(() => {
        setTopMargin(projects?.map(() => getRandomInt(2, parseInt((windowHeight - tileSize) / 10))))
    }, [projects])

    useEffect(() => {
        setProjectOpen(projects?.map((_, index) => {
            return (from?.i === index) ? true : false
        }))
    }, [from])

    const itemsRef = useRef([])

    useEffect(() => {
        const hashedTitles = projects?.map(pro => slugify(pro.title, { lower: true }))
        const hash = location?.hash
        if (hash[0] === '#' && projects) {
            var foundIndex = (hashedTitles?.indexOf(hash.substring(1)))
            if (foundIndex !== -1) {
                setRefs(itemsRef?.current)
                if (refs?.[foundIndex]) {
                    setLastTouched(foundIndex)
                    const from = calculatePosition(refs[foundIndex])
                    setFrom({ from, i: foundIndex })
                }
            }
        }
    }, [projects, refs])


    const anyOpen = projectOpen?.some(el => el === true)

    useEffect(() => {
        setProjectOpen(projects?.map(() => false))
    }, [windowHeight, windowWidth])

    useEffect(() => {
        const keyDownHandler = event => {
            if (anyOpen && event.key === 'Escape') {
                event.preventDefault()
                setProjectOpen(projects?.map(() => false))
                navigate('', { replace: true })
            }
        }
        document.addEventListener('keydown', keyDownHandler)
        return () => {
            document.removeEventListener('keydown', keyDownHandler)
        }
    }, [anyOpen])

    const getSlidesPerView = (projects?.length <= 2) ? projects?.length : 2.3

    return (
        <StyledSection open={open} section={section} backgroundColor={anyOpen ? 'white' : 'white'} >
            <Link style={{ position: 'absolute', zIndex: 99999999 }} to='/'>
                <SaloniLogo color='black' />
            </Link>

            <Mask
                active={anyOpen}
                onClick={() => setProjectOpen(projects.map(() => false))}
            />

            {topMargin && <Swiper
                allowSlideNext={anyOpen ? false : true}
                allowSlidePrev={anyOpen ? false : true}
                mousewheel={true}
                loop={(projects.length <= 2) ? false : true}
                slidesPerView={getSlidesPerView}
                speed={600}
                parallax={true}
                navigation={false}
                modules={[Parallax, Pagination, Navigation, Mousewheel, FreeMode]}
                style={{
                    height: '100%',
                    paddingLeft: 50
                }}
                onSlideChange={() => setProjectOpen(projects.map(() => false))}
            >



                {projects.map((project, i) => {
                    return (

                        <SwiperSlide
                            key={i}
                            style={{ zIndex: lastTouched === i ? 9999 : 9 }} >
                            <DescriptionWrapper
                                open={projectOpen?.[i]}
                            >
                                <RegularImage
                                    title={project.title}
                                    tileSize={tileSize}
                                    images={project.imagesCollection.items}
                                    onClick={projectOpen?.[i] ? () => {
                                        navigate('', { replace: true })
                                        setProjectOpen(projects.map(() => false))
                                    } : (e) => handleClick(e, i)}

                                    top={topMargin[i] * 10}
                                    open={projectOpen?.[i]}
                                    from={from}
                                    zIndex={lastTouched === i ? 9999 : 9}
                                    windowWidth={windowWidth}
                                    windowHeight={windowHeight}
                                    active={!anyOpen || projectOpen?.[i]}
                                    reff={el => itemsRef.current[i] = el}
                                />
                                <Description
                                    top={topMargin[i] * 10 + tileSize - 20}
                                    zIndex={lastTouched === i ? 9999 : 9}
                                    open={projectOpen?.[i]}
                                    from={from}
                                    windowWidth={windowWidth}
                                    windowHeight={windowHeight}
                                    title={project.title}
                                    subtitle={project.subtitle}
                                    active={!anyOpen || projectOpen[i]}
                                />
                            </DescriptionWrapper>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            }

        </StyledSection>

    )
}

export default Section;
