

const MenuButton = ({ onClick }) => {
    const color = 'black'

    return <>
        <svg
            onClick={onClick}
            style={{
                position: 'absolute',
                zIndex: 99999999,
                cursor: 'pointer',
                top: 18,
                right: 24,
            }}
            xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 24 21"
        >
            <rect width="24" height="3" fill={color}></rect>
            <rect width="24" height="3" transform="translate(0 9)" fill={color}></rect>
            <rect width="12" height="3" transform="translate(0 18)" fill={color}></rect>
        </svg>
    </>
}


export default MenuButton