import { useState, useEffect } from 'react'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'

const fontSize = 1

const rndmInt = (max, exclude) => {
    const _max = Math.floor(max)
    const result = Math.floor(Math.random() * (_max + 1))
    if (result - fontSize <= exclude) {
        return Math.floor(Math.random() * (exclude - fontSize + 1))
    } if (result + fontSize > exclude) {
        return Math.floor(Math.random() * (max - exclude + fontSize + 1)) + exclude + fontSize
    } else {
        return result
    }
}

const getRandomCoordinates = ({
    minX,
    maxX,
    minY,
    maxY,
}) => {
    const resultX = Math.random() * (maxX)
    const resultY = Math.random() * (maxY)
    if (resultX < minX && resultY < minY) {
        return [resultX + minX, resultY + minY]
    }
    return [resultX, resultY]
}

const StyledLetter = styled(animated.div)`
  text-transform: lowercase;
  line-height: 0;
  position: absolute;
  color: white;
  z-index: ${p => p.ordered ? 99999 : 0};

`

const Letter = ({ children, ordered, marginLeft, top, orderedRowTop, clientWidth, clientHeight, totalWidth, isMobile, temp }) => {
    const [firstRender, setFirstRender] = useState(true)

    useEffect(() => {
        const timeoutID = window.setTimeout(() => {
            setFirstRender(false)
        }, 1250)
        return () => window.clearTimeout(timeoutID)
    }, [])

    const [x, y] = getRandomCoordinates({
        minX: 200,
        maxX: clientWidth,
        minY: 140,
        maxY: clientHeight,
    })

    const styleE = useSpring({
        delay: isMobile ? rndmInt(1250) : (firstRender ? 1250 : rndmInt(500)),
        config: { mass: 2, friction: 32 },
        from: {
            x: !temp && isMobile ? x : (clientWidth / 2) - (totalWidth / 2),
            y: !temp && isMobile ? y : top,
        },
        to: {
            x: !temp && (ordered || isMobile) ? (clientWidth / 2) - (totalWidth / 2) : x,
            y: !temp && (ordered || isMobile) ? top : y,
        },
    })


    return (<StyledLetter style={{ marginLeft: marginLeft + 'px', ...styleE }}>
        {children}
    </StyledLetter>)
}

export default Letter
